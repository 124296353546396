import { LineClamp } from "@/app/_components/LineClamp"
import { ArrowRightIcon } from "@/app/_components/icons/ArrowRightIcon"
import { ArrowRightSmallIcon } from "@/app/_components/icons/ArrowRightSmallIcon"
import { clsx } from "clsx"
import * as styles from "./TextLinkBody.css"

type Size = "medium" | "small"
export type Type = "normal" | "underline" | "mainA" | "arrow"

export interface Props {
  size?: Size
  type?: Type
  text: string
  line?: number
}

export function TextLinkBody({
  size = "medium",
  // TODO: 実はUnderlineありがdefaultという想定のデザインらしいのでNext移植がある程度進んだらunderlineをdefaultにする
  type = "normal",
  text,
  line = 1
}: Props) {
  return (
    <span
      className={clsx(
        styles.textLinkBody,
        size === "small" && styles.textLinkBodySmall,
        type === "underline" && styles.textLinkBodyUnderline,
        type === "mainA" && styles.textLinkBodyMain,
        type === "arrow" && styles.textLinkBodyArrow
      )}
    >
      {type === "arrow" ? (
        <span
          className={clsx(
            styles.textWrapper,
            size === "small" && styles.textWrapperSmall
          )}
        >
          <LineClamp text={text} line={line} className={styles.lineClampText} />
          {size === "medium" ? <ArrowRightIcon /> : <ArrowRightSmallIcon />}
        </span>
      ) : (
        <LineClamp text={text} line={line} className={styles.lineClampText} />
      )}
    </span>
  )
}
