import { media } from "@/app/_store/atom"
import { useAtomValue } from "jotai"
import { useCallback, useEffect, useLayoutEffect, useState } from "react"

export const useModal = (initialShow = false) => {
  const [show, setShow] = useState(initialShow)

  const handleOpen = useCallback(() => {
    setShow(true)
  }, [])

  const handleClose = useCallback(() => {
    setShow(false)
  }, [])

  return [show, handleOpen, handleClose] as [
    typeof show,
    typeof handleOpen,
    typeof handleClose
  ]
}

const setScrollStyle = (element: HTMLElement, canScroll: boolean) => {
  if (canScroll) {
    element.style.overflow = "auto"
    element.style.overscrollBehavior = "contain"
  } else {
    element.style.overflow = "hidden"
    element.style.overscrollBehavior = "none"
  }
}

const MAX_DYNAMIC_VIEW_PORT_HEIGHT = 98
const MIN_DYNAMIC_VIEW_PORT_HEIGHT = 42
const HIDE_BOTTOM_SHEET_THRESHOLD_VIEW_PORT_HEIGHT = 30

export const useBottomSheet = (
  ref: React.RefObject<HTMLElement>,
  show: boolean,
  onClose: () => void,
  type: "simple" | "rich"
) => {
  const { isDesktop } = useAtomValue(media)

  // タッチを開始したかどうか
  const [isDragging, setIsDragging] = useState(false)
  // タッチしてから少しでも動かしたかどうか
  const [touchMoved, setTouchMoved] = useState(false)
  const [touchStartY, setTouchStartY] = useState(0)
  const [heightDvh, setHeightDvh] = useState(0)
  const [currentY, setCurrentY] = useState(0)

  // 初期状態のheightを最大化する処理
  useLayoutEffect(() => {
    if (ref.current === null || isDesktop) {
      return
    }
    if (show) {
      const element = ref.current
      if (type === "simple") {
        element.style.height = `${MAX_DYNAMIC_VIEW_PORT_HEIGHT}dvh`
        setScrollStyle(element, true)
      } else {
        element.style.height = `${MIN_DYNAMIC_VIEW_PORT_HEIGHT}dvh`
        setScrollStyle(element, false)
      }
    }
  }, [ref, show, isDesktop, type])

  useEffect(() => {
    if (ref.current === null) {
      return
    }

    // モーダルを開いたときにbodyをスクロールできないように固定する
    if (show) {
      const scrollY = window.scrollY
      document.body.style.overflow = "hidden"
      document.body.style.position = "fixed"
      document.body.style.top = `-${scrollY}px`
      document.body.style.width = "100%"

      return () => {
        document.body.style.overflow = ""
        document.body.style.position = ""
        document.body.style.top = ""
        document.body.style.width = ""
        window.scrollTo(0, scrollY)
      }
    }
  }, [show, ref])

  const handleStartDragging = useCallback(
    (e: React.TouchEvent) => {
      setIsDragging(true)
      setTouchStartY(e.touches[0].clientY)
      setTouchMoved(false)
      const element = ref.current
      if (element !== undefined && element !== null) {
        const viewportHeight = window.innerHeight
        setHeightDvh((element.clientHeight / viewportHeight) * 100)
      }
    },
    [ref]
  )

  const handleOnDragging = useCallback(
    (e: React.TouchEvent) => {
      if (!isDragging) {
        return
      }

      // 動いた判定の閾値を5pxに設定
      if (Math.abs(e.touches[0].clientY - touchStartY) > 5) {
        setTouchMoved(true)
      }

      const element = ref.current
      if (element === null || element === undefined) {
        return
      }

      // 現在のタッチ位置を取得
      const currentY = e.touches[0].clientY
      setCurrentY(currentY)

      // スクロール位置が最上位にいる以外はボトムシートを下げる操作を許可しない
      // スクロールのドラッグ操作と干渉しないようにtouchStartYを更新する
      if (element.scrollTop > 0) {
        setTouchStartY(currentY)
        return
      }

      // 移動距離を計算(初期位置 - 現在位置)
      const diff = currentY - touchStartY

      // 下にドラッグする操作の場合ボトムコンテンツのスクロールを無効化する
      if (touchStartY < currentY) {
        setScrollStyle(element, false)
      }

      element.style.transition = "none"
      // ビューポートの高さに基づいて、移動距離をvhに変換
      const viewportHeight = window.innerHeight
      const diffdVh = (diff / viewportHeight) * 100

      // 新しい高さをdvhで計算(初期高さdvh = 移動距離dvh)
      let newHeightdVh = heightDvh - diffdVh

      // 高さの最小値と最大値の制限を設定
      newHeightdVh = Math.max(newHeightdVh, 0)
      newHeightdVh = Math.min(newHeightdVh, MAX_DYNAMIC_VIEW_PORT_HEIGHT)

      element.style.height = `${newHeightdVh}dvh`
    },
    [heightDvh, isDragging, touchStartY, ref]
  )

  const handleStopDragging = useCallback(() => {
    if (!isDragging || !touchMoved) {
      return
    }

    setIsDragging(false)
    setTouchMoved(false)

    const element = ref.current
    if (element === null) {
      return
    }

    // トランジションを元に戻す
    element.style.transition = ""

    const viewportHeight = window.innerHeight
    const heightDvh = (element.clientHeight / viewportHeight) * 100
    setHeightDvh(heightDvh)

    // モーダルを全て表示する
    const showFullHeight = () => {
      element.style.height = `${MAX_DYNAMIC_VIEW_PORT_HEIGHT}dvh`
      setScrollStyle(element, true)
    }

    // モーダルを一部表示する
    const showPartialHeight = () => {
      element.style.height = `${MIN_DYNAMIC_VIEW_PORT_HEIGHT}dvh`
      setScrollStyle(element, false)
    }

    // モーダルを非表示にする
    const hideModal = () => {
      element.style.height = "0"
      setScrollStyle(element, false)
      setTimeout(() => {
        // アニメーションが終わり次第閉じる（200msとして決め打ち）
        onClose()
      }, 200)
    }

    if (type === "simple") {
      if (touchStartY > currentY) {
        // 上にドラッグした場合
        if (heightDvh >= HIDE_BOTTOM_SHEET_THRESHOLD_VIEW_PORT_HEIGHT + 15) {
          // 全て表示
          showFullHeight()
        } else {
          // 画面下部まで下げてモーダルを閉じる
          hideModal()
        }
      } else {
        // 下にドラッグした場合
        if (heightDvh >= MAX_DYNAMIC_VIEW_PORT_HEIGHT - 15) {
          // 全て表示
          showFullHeight()
        } else {
          // 画面下部まで下げてモーダルを閉じる
          hideModal()
        }
      }
    } else {
      // type: richの場合
      if (touchStartY > currentY) {
        // 上にドラッグした場合
        if (heightDvh >= HIDE_BOTTOM_SHEET_THRESHOLD_VIEW_PORT_HEIGHT + 15) {
          // 全て表示
          showFullHeight()
        } else if (
          heightDvh < HIDE_BOTTOM_SHEET_THRESHOLD_VIEW_PORT_HEIGHT + 15 &&
          heightDvh >= HIDE_BOTTOM_SHEET_THRESHOLD_VIEW_PORT_HEIGHT
        ) {
          // 一部表示
          showPartialHeight()
        }
      } else {
        // 下にドラッグした場合
        if (heightDvh < HIDE_BOTTOM_SHEET_THRESHOLD_VIEW_PORT_HEIGHT) {
          // 画面下部まで下げてモーダルを閉じる
          hideModal()
        } else if (heightDvh <= MAX_DYNAMIC_VIEW_PORT_HEIGHT - 15) {
          // 一部表示
          showPartialHeight()
        } else if (heightDvh <= MAX_DYNAMIC_VIEW_PORT_HEIGHT) {
          // 全て表示
          showFullHeight()
        }
      }
    }
  }, [isDragging, touchMoved, onClose, ref, currentY, touchStartY, type])

  return [handleStartDragging, handleOnDragging, handleStopDragging]
}

export const useHandleClickOutside = (
  ref: React.RefObject<HTMLElement>,
  callback: () => void,
  excludeSelectors?: string[]
) => {
  const handleClickOutside = useCallback(
    (e: MouseEvent) => {
      if (e.target === null || !("nodeType" in e.target)) {
        return
      }

      // シート内の要素をクリックした場合は何もしない
      if (ref.current === null || ref.current.contains(e.target as Node)) {
        return
      }

      // excludeSelectorsの要素がクリックされたかどうかをチェック
      // クリックされた場合は何もしない
      const isExcludeTarget =
        excludeSelectors?.some(selector => {
          const elements = document.querySelectorAll(selector)
          return Array.from(elements).some(element =>
            element.contains(e.target as Node)
          )
        }) || false

      if (isExcludeTarget) return

      if (!ref.current.contains(e.target as Node)) {
        // シート外をクリックした場合に、aタグだったりした時の挙動を防ぐ
        e.preventDefault()
        e.stopPropagation()
        callback()
      }
    },
    [ref, callback, excludeSelectors]
  )

  return handleClickOutside
}
