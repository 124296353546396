import { LineClamp } from "@/app/_components/LineClamp"
import { ArrowRightIcon } from "@/app/_components/icons/ArrowRightIcon"
import { RawStyleList } from "@/app/_components/rawStyle/RawStyleList"
import clsx from "clsx"
import * as styles from "./ListArrowLink.css"

interface Props {
  link: string
  text: string
  subText?: string
  badge?: React.ReactNode
  thumbnail?: React.ReactNode
  borderTop?: boolean
  borderBottom?: boolean
}

export function ListArrowLink({
  text,
  subText,
  badge,
  thumbnail,
  link,
  borderTop = false,
  borderBottom = true
}: Props) {
  return (
    <a
      className={clsx(
        styles.container,
        borderTop && styles.borderTop,
        borderBottom && styles.borderBottom
      )}
      href={link}
    >
      <RawStyleList>
        {thumbnail !== undefined ? (
          <div
            className={clsx(
              styles.list,
              badge !== undefined
                ? styles.withThumbnailAndBadge
                : styles.withThumbnail
            )}
          >
            {thumbnail}
            <LineClamp text={text} line={2} />
            {badge !== undefined && badge}
            <ArrowRightIcon className={styles.icon} />
          </div>
        ) : (
          <div
            className={clsx(
              styles.list,
              styles.textOnly,
              badge !== undefined && styles.withBadge
            )}
          >
            {subText !== undefined ? (
              <div className={styles.withSubText}>
                <LineClamp text={text} line={1} />
                <LineClamp className={styles.subText} text={subText} line={1} />
              </div>
            ) : (
              <LineClamp text={text} line={2} />
            )}
            {badge !== undefined && badge}
            <ArrowRightIcon className={styles.icon} />
          </div>
        )}
      </RawStyleList>
    </a>
  )
}
