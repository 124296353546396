"use client"

import { RawStyleAccordion } from "@/app/_components/Accordion/RawStyleAccordion"
import { useCallback, useRef, useState } from "react"
import { CSSTransition } from "react-transition-group"
import * as styles from "./index.css"

interface Props {
  text: string
  subText?: string
  thumbnail?: React.ReactNode
  initialOpen?: boolean
  noSidePadding?: boolean
  children: React.ReactNode
}

export function Accordion({
  text,
  subText,
  thumbnail,
  initialOpen = false,
  noSidePadding = false,
  children
}: Props) {
  const [open, setOpen] = useState(initialOpen)
  const [showContent, setShowContent] = useState(initialOpen)
  const nodeRef = useRef(null)

  const handleClick = useCallback(() => {
    setOpen(!open)
  }, [open])
  // Accordionのopenと同時に中身を表示する
  const handleEnterSlide = useCallback(() => {
    setShowContent(true)
  }, [])
  // Accordionのcloseアニメーションが終了すると同時に中身を非表示にする
  const handleExitSlide = useCallback(() => {
    setShowContent(false)
  }, [])

  return (
    <div>
      <RawStyleAccordion
        text={text}
        subText={subText}
        thumbnail={thumbnail}
        open={showContent}
        noSidePadding={noSidePadding}
        onClick={handleClick}
      />
      <div>
        <CSSTransition
          nodeRef={nodeRef}
          in={open}
          timeout={200}
          classNames={styles.slideTransitionGroup}
          onEnter={handleEnterSlide}
          onExited={handleExitSlide}
        >
          <div
            ref={nodeRef}
            className={styles.contents}
            style={{ display: showContent ? "block" : "none" }}
          >
            {children}
          </div>
        </CSSTransition>
      </div>
    </div>
  )
}
