import { ArrowCloseIcon } from "@/app/_components/icons/ArrowCloseIcon"
import { ArrowOpenIcon } from "@/app/_components/icons/ArrowOpenIcon"
import { clsx } from "clsx"
import * as styles from "./index.css"

interface Props {
  text: string
  subText?: string
  thumbnail?: React.ReactNode
  open: boolean
  borderAllSides?: boolean
  ellipsis?: boolean
  noSidePadding?: boolean
  onClick(): void
}

export function RawStyleAccordion({
  text,
  subText,
  thumbnail,
  open,
  borderAllSides = false,
  ellipsis = true,
  noSidePadding = false,
  onClick
}: Props) {
  return (
    <div
      className={clsx(
        styles.accordion,
        !open && styles.accordionClosed,
        thumbnail !== undefined
          ? subText !== undefined
            ? styles.accordionWithThumbnailAndSubText
            : styles.accordionWithThumbnail
          : undefined,
        borderAllSides && styles.accordionWithAllSidesBorder,
        noSidePadding && styles.noSidePadding
      )}
      onClick={onClick}
    >
      {thumbnail !== undefined && (
        <div className={styles.thumbnail}>{thumbnail}</div>
      )}

      <span className={clsx(styles.text, ellipsis && styles.textWithEllipsis)}>
        {text}
      </span>

      {subText !== undefined && (
        <span className={styles.subText}>{subText}</span>
      )}

      {open ? (
        <ArrowCloseIcon className={styles.toggleIcon} />
      ) : (
        <ArrowOpenIcon className={styles.toggleIcon} />
      )}
    </div>
  )
}
