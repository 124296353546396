import { useEffect, useRef, useState } from "react"

export const useThrottle = <T>(value: T, ms = 200) => {
  const [throttledValue, setThrottledValue] = useState(value)
  const lastRan = useRef(Date.now())

  // 実行タイミングは初回、最後の実行からms経過後、最後の実行の3パターン
  useEffect(() => {
    const timer = setTimeout(
      () => {
        if (Date.now() - lastRan.current >= ms) {
          setThrottledValue(value)
          lastRan.current = Date.now()
        }
      },
      ms - (Date.now() - lastRan.current)
    )

    return () => {
      clearTimeout(timer)
    }
  }, [value, ms])

  return throttledValue
}
