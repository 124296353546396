import { clsx } from "clsx"
import * as styles from "./index.css"

interface Props {
  main?: boolean
  vertical?: boolean
  className?: string
}

export function Border({ main = false, vertical = false, className }: Props) {
  return (
    <hr
      className={clsx(
        styles.border,
        main && styles.main,
        vertical && styles.vertical,
        className
      )}
    />
  )
}
