"use client"

import { clsx } from "clsx"
import {
  type ComponentPropsWithRef,
  type Ref,
  forwardRef,
  useCallback,
  useMemo,
  useState
} from "react"
import { Border } from "../Border"
import { CloseIcon } from "../icons/CloseIcon"
import { CloseSmallIcon } from "../icons/CloseSmallIcon"
import { SearchIcon } from "../icons/SearchIcon"
import { SearchSmallIcon } from "../icons/SearchSmallIcon"
import * as styles from "./index.css"

type Variant = "medium" | "small"

interface Props extends ComponentPropsWithRef<"input"> {
  variant?: Variant
  error?: string
  onClear: () => void
  onSearch: () => void
}

function _TextFieldSearch(
  {
    variant = "medium",
    error,
    onClear,
    onSearch,
    className,
    type = "text",
    onFocus,
    onBlur,
    ...props
  }: Props,
  ref: Ref<HTMLInputElement>
) {
  const [isFocused, setIsFocused] = useState(false)

  const isEmpty = useMemo(() => {
    return props.value === undefined || props.value === ""
  }, [props.value])

  const isShownLeftIcon = useMemo(() => {
    return !isFocused && isEmpty
  }, [isFocused, isEmpty])

  const isShownRightIcon = useMemo(() => {
    return isFocused || !isEmpty
  }, [isFocused, isEmpty])

  const handleFocus = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      setIsFocused(true)
      onFocus?.(event)
    },
    [onFocus]
  )

  const handleBlur = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      setIsFocused(false)
      onBlur?.(event)
    },
    [onBlur]
  )

  const handleClear = useCallback(() => {
    onClear()
    setIsFocused(false)
  }, [onClear])

  const handleSearch = useCallback(() => {
    // 未入力の場合はClearと同じ挙動
    if (isEmpty) {
      handleClear()
      return
    }
    onSearch()
  }, [isEmpty, handleClear, onSearch])

  return (
    <div className={className}>
      <div
        className={clsx(styles.container, variant === "small" && styles.small)}
      >
        {isShownLeftIcon &&
          (variant === "small" ? (
            <SearchSmallIcon className={clsx(styles.searchIcon, styles.left)} />
          ) : (
            <SearchIcon className={clsx(styles.searchIcon, styles.left)} />
          ))}

        <input
          {...props}
          ref={ref}
          className={clsx(
            styles.input,
            isEmpty && styles.empty,
            variant === "small" && styles.smallInput,
            isShownLeftIcon && styles.withLeftIcon,
            isFocused && styles.witRightIcon,
            props.disabled && styles.disabled
          )}
          type="text"
          onFocus={handleFocus}
          onBlur={handleBlur}
        />

        {isShownRightIcon && (
          <div className={styles.rightIconContainer}>
            {!isEmpty &&
              (variant === "small" ? (
                <CloseSmallIcon
                  className={clsx(styles.clearIcon)}
                  onClick={handleClear}
                />
              ) : (
                <CloseIcon
                  className={clsx(styles.clearIcon)}
                  onClick={handleClear}
                />
              ))}
            <Border vertical className={styles.border} />
            {variant === "small" ? (
              <SearchSmallIcon
                className={clsx(styles.searchIcon, styles.right)}
                onClick={handleSearch}
              />
            ) : (
              <SearchIcon
                className={clsx(styles.searchIcon, styles.right)}
                onClick={handleSearch}
              />
            )}
          </div>
        )}
      </div>
      {error !== undefined && error !== "" && (
        <p
          className={clsx(
            styles.errorText,
            variant === "small" && styles.errorTextSmall
          )}
        >
          {error}
        </p>
      )}
    </div>
  )
}

export const TextFieldSearch = forwardRef<HTMLInputElement, Props>(
  _TextFieldSearch
)
