import "../../../../node_modules/@vanilla-extract/next-plugin/node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2Fapp%2F_assets%2Fstyles%2Fvariables.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA3WV32%2BbMBDH3%2FdXnISmbBKp%2FAMM3N72sE2aNk3r0x6NsVOaBNJA2nTT%2FvfJpF19hsYhUj7%2BHr7j7jg89v0If94ArNdju%2BvGA0NIGiONVB8CyhES57TShIqJ2trWIZUTdSHKPMr8CmmOkFjhV0gVQmJyv0JaICSV8yukJUJSKL9CWiEkYvqEVCMkmeQhqhESxliIDMJxU%2Bt3LIWn75XM34eKZq6gAjsXKCJwcwGTRLFBSPKcPICbJyOR5ym8%2FLCrjFi2l0RppkN6OwXalKYI6RYh0XWhjAzpzp9dF86QXO09VXVtydPqfAadUhWhPUIidSPpaYfFwrpDSHhZFI7k6jhPzOCPUowJ4uw4F54mpLPGhvR%2BonVFtQ%2FeU5NpRegZYa0Ph51dD4%2FDaPcpfNy13fabNtfT%2F099N6aw%2BtnX%2FdivUlh9aY9603Y9XOtuIOCr3p7gcz%2FetAZ%2BHPvvfvPXM1mlMOhuWA%2F22JKyfrwUcIh%2BP6VfZik8X3FdcbYoiuqX83l%2FcvFadRXUVr6mi87Ilt8i%2BbO5YCkolkJ%2BCSOyVsuyOJACQWSHM2ElAi8jViFwFTGNwGPbGoGLiBkEziLWILArMYDVg133pxHYQPat35dP%2B20Xbzu%2FTZ%2FLZrIg%2Fc5vEHL2lqAWIfb5FiEOd7sQ7s6Hwe73muL9hEnx8w4h6hLeI5QROiAUjLxM%2BZ1HVHVEUBEafFgUjQhZhE4IMqfoHkFGqgcEEaHzHPluYlmdN9Td3whJzarIP%2BHnX1HL0pFsCN80thHOkPeq8BOwqZTNSoL9CLTGcUfV0xjUTlriifBz0ElrHB2ualL72xBcXLC1huBynnVRzYpDaAQG4nCeqhrYpc%2Byl0HES9Jjov6vV4t6wajeeP10b7F8gBDUoJkM%2FM19Ly8ZeI%2F%2B%2FgNe%2Fdw9qQgAAA%3D%3D%22%7D"
import "../../../../node_modules/@vanilla-extract/next-plugin/node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2Fapp%2F_components%2FAccordion%2Findex.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA8VUS3PbIBC%2B%2B1fs0TngkRI%2FyaV%2Fo6cOFitrGwQUsC2n4%2F%2FeASuyFD%2FiaQ%2B9SMyy32PZhcmP%2FJfTB5Xl8HsEIMlbJQ4cNo7k6wjSnwWsrRIBWWHUttaeQ146ENtgYopQtNGMAtaeQ4E6oEtIYTksbRPXxdZ54zhYQx%2FbW4%2BOeVRYBA7aaIxBK6QkveGQz23TfmYniuCE9hTIaA7GioLCAXbCjRkLpHSwuXx6HR1Hk66ebpWlyobUWcvbAzyntLVxEh1bmxBMzSG3DXijSA60ZkOplx7yHqRjd0LS1vOh%2F59D0mkivXH88eh7PejBZl%2FCbmPnCVsqbJivHOk3Dll0XQu3Ic0UloF3XS%2BMih3tl3AYVrBIdIo0sgppU4VE10tYXSbkk%2Blj5CJh9xUFZN6KAuMQ7Z2waVawCczs0JXK7DmgUmQ9%2Bbh1jlYkJeoBaZFIr4i%2Fp%2B5dOu1h5V%2BPD7J0ZxI%2BTXlpXM1Py9i%2F72O2tE1y0A7%2B54NsGZgoAu3wTNRel4trcksnG4rkn0UaCndcPoD%2BZ4c3TuJbjZIE%2BMIhahBawrgmzfYkQ8VhNc9s85REz88Dr%2BIopGCPbWCkTErHPmzZAkqjA%2FP0jhzy6emBGuStr%2BQ9f%2BR95Xcxj1W2YdF0ZcxWF2Vc8%2FPyoJ%2F8vp%2Bz8GK%2B%2BD%2FCXcWj4x9oR2gZqAYAAA%3D%3D%22%7D"
export var accordion = '_1qrnyl01';
export var accordionClosed = '_1qrnyl02';
export var accordionWithAllSidesBorder = '_1qrnyl03';
export var accordionWithThumbnail = '_1qrnyl04';
export var accordionWithThumbnailAndSubText = '_1qrnyl05';
export var contents = '_1qrnyl0d';
export var noSidePadding = '_1qrnyl00';
export var slideTransitionGroup = '_1qrnyl0e';
export var subText = '_1qrnyl0c _1qrnyl0b';
export var text = '_1qrnyl09 _1qrnyl08';
export var textWithEllipsis = '_1qrnyl0a';
export var thumbnail = '_1qrnyl07';
export var toggleIcon = '_1qrnyl06';