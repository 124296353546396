"use client"

import { isAppUA } from "@/app/_store/atom"
import { useAtomValue } from "jotai"
import Link, { type LinkProps } from "next/link"

interface Props extends LinkProps {
  className?: string
  children: React.ReactNode
}

// Nuxtの名残り
// アプリでNuxtLinkを用いているとaタグのハンドラが作動しなかったhistoryがおかしくなる問題が発生していたため、アプリでのみただのaタグに戻していた
// next/linkでも必要なのかは不明
export function NextLinkWrap({ href, className, children, ...rest }: Props) {
  const isApp = useAtomValue(isAppUA)

  return (
    <>
      {isApp ? (
        <a
          href={typeof href === "string" ? href : (href.href ?? "")}
          className={className}
        >
          {children}
        </a>
      ) : (
        <Link href={href} className={className} {...rest}>
          {children}
        </Link>
      )}
    </>
  )
}
