import { clsx } from "clsx"
import * as styles from "./index.css"

export interface Props {
  size?: "medium" | "small"
  enableHover?: boolean
  children: React.ReactNode
}

export function RawStyleList({
  size = "medium",
  enableHover = true,
  children
}: Props) {
  return (
    <div
      className={clsx(
        styles.list,
        enableHover && styles.enableHover,
        size === "small" ? styles.small : styles.medium
      )}
    >
      {children}
    </div>
  )
}
