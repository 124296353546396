import clsx from "clsx"
import * as styles from "./index.css"

interface Props {
  size?: "medium" | "small"
  rank: number
}

export function RankLabel({ size = "medium", rank }: Props) {
  return (
    <div className={clsx(styles.rankLabel, size === "small" && styles.small)}>
      <div
        className={clsx(
          styles.label,
          rank === 1 && styles.no1,
          rank === 2 && styles.no2,
          rank === 3 && styles.no3
        )}
      >
        <span className={styles.value}>{rank}</span>
      </div>
    </div>
  )
}
