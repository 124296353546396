import { NextLinkWrap } from "@/app/_components/NextLinkWrap"
import clsx from "clsx"
import { TextLinkBody, type Props as TextLinkBodyProps } from "./TextLinkBody"
import * as styles from "./index.css"

interface Props extends TextLinkBodyProps {
  link: string
  targetBlank?: boolean
  isNextLink?: boolean
  className?: string
}

export function TextLink({
  link,
  targetBlank = false,
  isNextLink = false,
  className,
  ...rest
}: Props) {
  return (
    <>
      {isNextLink ? (
        <NextLinkWrap href={link} className={clsx(styles.textLink, className)}>
          <TextLinkBody {...rest} />
        </NextLinkWrap>
      ) : (
        <a
          href={link}
          target={targetBlank ? "_blank" : undefined}
          rel={targetBlank ? "noopener noreferrer" : undefined}
          className={clsx(styles.textLink, className)}
        >
          <TextLinkBody {...rest} />
        </a>
      )}
    </>
  )
}
