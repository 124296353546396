import { HeaderSearchMenuListIncrement } from "@/app/_components/Header/HeaderSearchMenu/HeaderSearchMenuListIncrement"
import { HeaderSearchMenuListPopular } from "@/app/_components/Header/HeaderSearchMenu/HeaderSearchMenuListPopular"
import { fetchSuggest } from "@/app/_components/Header/HeaderSearchMenu/utils"
import { IndicatorCircular } from "@/app/_components/IndicatorCircular"
import { useThrottle } from "@/app/_hooks/throttle"
import { useCallback, useEffect, useState } from "react"
import * as styles from "./HeaderSearchMenuSuggestPc.css"

interface Props {
  keyword: string
}

export function HeaderSearchMenuSuggestPc({ keyword: rawKeyword }: Props) {
  const [type, setType] = useState<"popular" | "suggest">("popular")
  const [items, setItems] = useState<{ name: string; url: string }[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const keyword = useThrottle(rawKeyword, 1000)

  useEffect(() => {
    setType(keyword.length > 1 ? "suggest" : "popular")

    fetch()
  }, [keyword])

  const fetch = useCallback(async () => {
    if (keyword.length === 1) {
      return
    }

    setIsLoading(true)

    const response = await fetchSuggest(keyword)
    setItems(response.slice(0, 10))

    setIsLoading(false)
  }, [keyword])

  return (
    <div className={styles.container}>
      {isLoading ? (
        <div className={styles.loading}>
          <IndicatorCircular size="medium" />
        </div>
      ) : type === "popular" ? (
        <HeaderSearchMenuListPopular items={items} />
      ) : (
        <HeaderSearchMenuListIncrement items={items} />
      )}
    </div>
  )
}
