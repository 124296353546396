"use client"

import { ListArrowLink } from "@/app/_components/List/ListArrowLink"
import * as styles from "./HeaderSearchMenuListIncrement.css"

interface Props {
  items: {
    name: string
    url: string
  }[]
}

export function HeaderSearchMenuListIncrement({ items }: Props) {
  return (
    <div className={styles.container}>
      {items.map(item => (
        <ListArrowLink text={item.name} link={item.url} key={item.name} />
      ))}
    </div>
  )
}
