"use client"

import { ListArrowLink } from "@/app/_components/List/ListArrowLink"
import { RankLabel } from "@/app/_components/RankLabel"
import * as styles from "./HeaderSearchMenuListPopular.css"

interface Props {
  items: {
    name: string
    url: string
  }[]
}

export function HeaderSearchMenuListPopular({ items }: Props) {
  return (
    <div className={styles.container}>
      <div className={styles.header}>人気検索ワード</div>
      {items.map((item, index) => (
        <ListArrowLink
          thumbnail={<RankLabel rank={index + 1} size="small" />}
          text={item.name}
          link={item.url}
          key={item.name}
        />
      ))}
    </div>
  )
}
