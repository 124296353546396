import { fetcher } from "@/app/_utils/fetch"

export async function fetchSuggest(keyword: string) {
  const isIncremental = keyword.length > 1

  // 文字入力の変化ごとにサジェストを取得する
  const pathname = isIncremental
    ? "/v2/apis/components/suggest_keywords/incremental_keyword"
    : "/v2/apis/components/suggest_keywords/tag_default"

  const url = new URL(pathname, process.env.NEXT_PUBLIC_ORIGIN)
  if (isIncremental) {
    url.searchParams.set("keyword", keyword)
  }

  const { body } = await fetcher<{
    body: { name: string; url: string }[]
  }>(url)

  return body
}
