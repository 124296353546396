"use client"

import { HeaderSearchMenuSuggestPc } from "@/app/_components/Header/HeaderSearchMenu/HeaderSearchMenuSuggestPc"
import { TextFieldSearch } from "@/app/_components/TextFieldSearch"
import { useHandleClickOutside } from "@/app/_hooks/modal"
import clsx from "clsx"
import { usePathname, useSearchParams } from "next/navigation"
import { useCallback, useRef, useState } from "react"
import * as styles from "./HeaderSearchMenuPc.css"

interface Props {
  variant?: "small" | "medium"
  placeholder?: string
}

export function HeaderSearchMenuPc({
  variant = "small",
  placeholder = "検索する"
}: Props) {
  const pathname = usePathname()
  const searchParams = useSearchParams()
  const [value, setValue] = useState(
    (pathname === "/tag/search" && searchParams.get("keyword")) || ""
  )
  const [showSuggest, setShowSuggest] = useState(false)
  const ref = useRef<HTMLDivElement>(null)

  const handleClearSearch = useCallback(() => {
    setValue("")
    setShowSuggest(false)
  }, [])

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.currentTarget.value)
  }, [])

  const handleFocus = useCallback(() => {
    if (!showSuggest) {
      document.addEventListener("click", handleClickOutside)
      setShowSuggest(true)
    }
  }, [showSuggest])

  const handleCloseSuggest = useCallback(() => {
    document.removeEventListener("click", handleClickOutside)
    setShowSuggest(false)
  }, [])

  const handleClickOutside = useHandleClickOutside(ref, handleCloseSuggest)

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.nativeEvent.isComposing || e.key !== "Enter") {
        return
      }
      const url = new URL("/tag/search", process.env.NEXT_PUBLIC_ORIGIN)
      url.searchParams.append("keyword", value)

      // 広告があるためSPA遷移は使わない
      window.location.href = url.href

      handleCloseSuggest()
    },
    [value, handleCloseSuggest]
  )

  const handleClickSearch = useCallback(() => {
    const url = new URL("/tag/search", process.env.NEXT_PUBLIC_ORIGIN)
    url.searchParams.append("keyword", value)

    // 広告があるためSPA遷移は使わない
    window.location.href = url.href

    handleCloseSuggest()
  }, [value, handleCloseSuggest])

  return (
    <div className={styles.container}>
      <div
        className={clsx(
          styles.searchInputContainer,
          variant === "medium" && styles.medium
        )}
        ref={ref}
      >
        <TextFieldSearch
          onClear={handleClearSearch}
          onSearch={handleClickSearch}
          variant={variant}
          placeholder={placeholder}
          value={value}
          onKeyDown={handleKeyDown}
          onChange={handleChange}
          onFocus={handleFocus}
        />

        {showSuggest && (
          <div
            className={clsx(
              styles.suggest,
              variant === "medium" && styles.medium
            )}
          >
            <HeaderSearchMenuSuggestPc keyword={value} />
          </div>
        )}
      </div>
    </div>
  )
}
